







































import { Component, Prop, Vue } from 'vue-property-decorator'

import { benefitLogo, MILES_PER_PLN } from '../../shared'
import { BenefitProgram, LinkData } from '../../contracts/programs'

import { MilesAndMoreCounterProps } from './MilesAndMoreCounter.contracts'
import { TabOption } from '../../../../dsl/abstract/components/Link/Link.contracts'
import { Inject } from '../../../../support'
import { ISiteService, SiteServiceType } from '../../../../contexts'

/**
 * @author Agnieszka Zawadzka <agnieszka.zawadzka@movecloser.pl>
 */
@Component<MilesAndMoreCounter>({
  name: 'MilesAndMoreCounter',
  created () {
    this.composeProgramLink()
  }
})
export class MilesAndMoreCounter extends Vue {
  @Inject(SiteServiceType)
  public readonly siteService!: ISiteService

  @Prop({ required: false })
  public readonly loyaltyPayload?: MilesAndMoreCounterProps['loyaltyPayload']

  @Prop({ type: String, required: false })
  public readonly text?: MilesAndMoreCounterProps['text']

  @Prop({ type: Number, required: true })
  public readonly total?: MilesAndMoreCounterProps['total']

  public readonly logo: string = benefitLogo[BenefitProgram.MilesAndMore]

  public count: number = this.total ? Math.floor(this.total * MILES_PER_PLN) : 0
  public label: string =
    this.text ?? this.$t('front.loyalty.molecules.MilesAndMoreCounter.text').toString()

  public link: LinkData | null = null

  public get isMMLoyaltyLimitSet (): boolean {
    if (!this.loyaltyPayload || typeof this.loyaltyPayload.milesAndMorePoints === 'undefined' || typeof this.loyaltyPayload.milesAndMorePointsLimit === 'undefined') {
      return false
    }
    return this.loyaltyPayload.milesAndMorePoints >= this.loyaltyPayload.milesAndMorePointsLimit
  }

  public get isMMLoyaltyLimitNear (): boolean {
    if (!this.loyaltyPayload || typeof this.loyaltyPayload.milesAndMorePoints === 'undefined' || typeof this.loyaltyPayload.milesAndMorePointsLimit === 'undefined') {
      return false
    }
    return this.loyaltyPayload.milesAndMorePoints + this.count >= this.loyaltyPayload.milesAndMorePointsLimit
  }

  public get pointsCount (): number {
    if (!this.loyaltyPayload || typeof this.loyaltyPayload.milesAndMorePoints === 'undefined' || typeof this.loyaltyPayload.milesAndMorePointsLimit === 'undefined') {
      return this.count
    }
    return this.isMMLoyaltyLimitSet
      ? 0
      : this.isMMLoyaltyLimitNear
        ? this.loyaltyPayload.milesAndMorePointsLimit - this.loyaltyPayload.milesAndMorePoints
        : this.count
  }

  protected composeProgramLink (): void {
    if (!this.siteService) {
      return
    }

    const siteLinks = this.siteService.getActiveSiteUrls()

    if (siteLinks[BenefitProgram.MilesAndMore]) {
      this.link = {
        label: BenefitProgram.MilesAndMore,
        target: siteLinks[BenefitProgram.MilesAndMore],
        tabTarget: TabOption.Blank,
        title: BenefitProgram.MilesAndMore
      }
    }
  }
}

export default MilesAndMoreCounter
